import _ from 'lodash';

import { Gender, Goal } from '@/models/api';

import { roundTotal } from './helpers';

export const BUCKET_CONFIG = {
  [Goal.MGAIN]: {
    calConfig: { base: 36, coe: 4 },
    pConfig: { base: 0.16, coe: 0.01 },
    cConfig: { base: 0.46, coe: 0.01 },
    fConfig: { base: 0.38, coe: -0.02 },
  },
  [Goal.CCTRL]: {
    calConfig: { base: 29, coe: 3 },
    pConfig: { base: 0.26, coe: -0.01 },
    cConfig: { base: 0.39, coe: 0.01 },
    fConfig: { base: 0.35, coe: 0 },
  },
  [Goal.WLOSS]: {
    calConfig: { base: 28, coe: 2.7 },
    pConfig: { base: 0.3, coe: -0.005 },
    cConfig: { base: 0.35, coe: 0.005 },
    fConfig: { base: 0.35, coe: 0 },
  },
  [Goal.PERF]: {
    calConfig: { base: 31, coe: 2 },
    pConfig: { base: 0.25, coe: -0.005 },
    cConfig: { base: 0.4, coe: 0.005 },
    fConfig: { base: 0.35, coe: 0 },
  },
};

export const calculateDailyCalories = (
  goal: Goal,
  gender: Gender,
  weight: number,
  bucket = 0,
): number => {
  const { calConfig } = _.get(BUCKET_CONFIG, goal, BUCKET_CONFIG[Goal.MGAIN]);
  const offset = gender === Gender.MALE ? 0 : 7.5;
  // Cal/KG
  const calKg =
    ((calConfig.base + bucket * calConfig.coe) * (100 - offset)) / 100;
  // calculate macros
  const cal = roundTotal(calKg * (weight || 0), 0);

  return cal;
};
