import type { FC } from 'react';

import type { TrackerEvent } from '@/utils/track/tracker.constant';
import { trackPlasmicEvent } from '@/utils/track/tracker.helper';

interface TrackerParentProps {
  children: React.ReactNode;
  eventName: TrackerEvent;
  eventProperties: Record<string, any>;
}

const TrackerParent: FC<TrackerParentProps> = ({
  children,
  eventName,
  eventProperties,
}) => {
  return (
    <div onClick={() => trackPlasmicEvent(eventName, eventProperties)}>
      {children}
    </div>
  );
};

export default TrackerParent;
