import { observer } from 'mobx-react-lite';
import React from 'react';

import { useStore } from '@/models/root-store';

interface InputElementProps {
  label: string;
  type: string;
  checked?: boolean;
  onChange?: () => void;
  dataInput?: any;
  disabled?: boolean;
  search?: boolean;
  value?: any;
  readOnly?: boolean;
}

const InputElement: React.FC<InputElementProps> = observer(
  ({
    label,
    type = 'checkbox',
    onChange,
    checked = false,
    // dataInput,
    search,
    ...rest
  }: InputElementProps) => {
    const {
      productStore: {
        filters: {
          selectedFilters: { isRangeInSearch },
        },
      },
    } = useStore();
    const textSize = type === 'checkbox' ? 'text-sm md:text-base' : '';
    const { disabled, value } = rest;
    if (
      disabled &&
      search &&
      value?.key === 'menuHierarchy' &&
      isRangeInSearch(value?.value)
    )
      return null;

    return (
      <label
        className={`flex items-center space-x-2 py-0.5 font-interMedium md:py-0 ${textSize}`}
      >
        <input
          type={type}
          className="w-4 accent-black"
          checked={checked}
          onChange={onChange}
          {...rest}
        />
        {label && <span>{label}</span>}
      </label>
    );
  },
);

export default InputElement;
