import _ from 'lodash';
import type { FC } from 'react';
import React, { useState } from 'react';
import ReactSelect from 'react-select';

import { Gender, Goal } from '@/models/api';
import { calculateDailyCalories } from '@/utils/calorie-calculator';

import InputElement from '../common/InputElement/InputElement';

export type Props = {
  className?: string;
};

const customSelectStyles = {
  control: (provided: any) => ({
    ...provided,
    fontSize: '14px', // Change font size for the control (input area)
    minWidth: '100px',
  }),
  option: (provided: any) => ({
    ...provided,
    fontSize: '14px', // Change font size for each option
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontSize: '14px', // Change font size for the selected value
  }),
  menu: (provided: any) => ({
    ...provided,
    fontSize: '14px', // Change font size for the dropdown menu
  }),
};

const ageButtonStyles =
  'w-full rounded-lg border p-3 text-sm font-medium transition-all hover:bg-gray-50 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-black';

const CalorieCalculator: FC<Props> = ({ className }: Props) => {
  const [targetCalories, setTargetCalories] = useState<number | null>(null);
  const [age, setAge] = useState<number | null>(null);
  const [gender, setGender] = useState<Gender | null>(null);
  const [weight, setWeight] = useState<number | null>(null);
  const [activityLevel, setActivityLevel] = useState<number | null>(null);
  const [goal, setGoal] = useState<Goal | null>(null);
  const calculateCalories = () => {
    if (
      !age ||
      !gender ||
      !weight ||
      _.isNull(activityLevel) ||
      _.isNull(goal)
    ) {
      return;
    }

    const result = calculateDailyCalories(goal, gender, weight, activityLevel);
    setTargetCalories(result);
  };
  return (
    <div className={`flex flex-col ${className}`}>
      <div>
        <div className="w-full max-w-2xl rounded-lg bg-white p-8 shadow-lg">
          <form id="calorie-form" className="space-y-6">
            <div className="grid grid-cols-1 gap-y-6 md:grid-cols-2 md:gap-x-6">
              <div className="space-y-6 md:border-r md:border-gray-200 md:pr-10">
                <div>
                  <label className="mb-3 block text-sm font-medium text-gray-700">
                    My age range is
                  </label>
                  <div className="grid grid-cols-2 gap-3">
                    <button
                      type="button"
                      className={`${ageButtonStyles} ${age === 21 ? 'border-black bg-gray-100' : ''}`}
                      onClick={() => setAge(21)}
                    >
                      <strong>18-25</strong>
                    </button>
                    <button
                      type="button"
                      className={`${ageButtonStyles} ${age === 32 ? 'border-black bg-gray-100' : ''}`}
                      onClick={() => setAge(32)}
                    >
                      <strong>26-39</strong>
                    </button>
                    <button
                      type="button"
                      className={`${ageButtonStyles} ${age === 44 ? 'border-black bg-gray-100' : ''}`}
                      onClick={() => setAge(44)}
                    >
                      <strong>40-49</strong>
                    </button>
                    <button
                      type="button"
                      className={`${ageButtonStyles} ${age === 55 ? 'border-black bg-gray-100' : ''}`}
                      onClick={() => setAge(55)}
                    >
                      <strong>49+</strong>
                    </button>
                  </div>
                </div>

                <div
                  id="gender-section"
                  className={age ? '' : 'pointer-events-none opacity-50'}
                >
                  <label className="mb-3 block text-sm font-medium text-gray-700">
                    Gender
                  </label>
                  <div className="flex space-x-6 text-sm text-gray-700">
                    <InputElement
                      label="Male"
                      type="radio"
                      checked={gender === Gender.MALE}
                      onChange={() => {
                        setGender(Gender.MALE);
                      }}
                    />
                    <InputElement
                      label="Female"
                      type="radio"
                      checked={gender === Gender.FEMALE}
                      onChange={() => {
                        setGender(Gender.FEMALE);
                      }}
                    />
                  </div>
                </div>

                <div
                  id="weight-section"
                  className={
                    age && gender ? '' : 'pointer-events-none opacity-50'
                  }
                >
                  <label className="mb-3 block text-sm font-medium text-gray-700">
                    My current weight is
                  </label>
                  <input
                    type="number"
                    id="weight"
                    className="w-full rounded-lg border p-3 text-sm transition-all focus:border-transparent focus:outline-none focus:ring-2 focus:ring-black"
                    placeholder="Weight (kg)"
                    required
                    onChange={(e) => setWeight(Number(e.target.value))}
                    value={`${weight}`}
                  ></input>
                </div>
              </div>

              <div className="space-y-6 md:pl-6">
                <div
                  id="activity-section"
                  className={
                    age && gender && weight
                      ? ''
                      : 'pointer-events-none opacity-50'
                  }
                >
                  <label className="mb-3 block text-sm font-medium text-gray-700">
                    Activity Level
                  </label>
                  <ReactSelect
                    key={'activity-select'}
                    options={[
                      { label: 'Lightly active', value: 0 },
                      { label: 'Moderately active', value: 2 },
                      { label: 'Very active', value: 4 },
                    ]}
                    isSearchable={false}
                    placeholder={'Select activity level'}
                    onChange={(e) => {
                      setActivityLevel(e ? e.value : null);
                    }}
                    styles={customSelectStyles}
                  />
                </div>

                <div
                  id="goal-section"
                  className={
                    age && gender && weight && !_.isNull(activityLevel)
                      ? ''
                      : 'pointer-events-none opacity-50'
                  }
                >
                  <label className="mb-3 block text-sm font-medium text-gray-700">
                    Goal
                  </label>
                  <ReactSelect
                    key={'goal-select'}
                    options={[
                      { label: 'Lose Weight', value: Goal.WLOSS },
                      { label: 'Control Calories', value: Goal.CCTRL },
                      { label: 'Gain Muscle', value: Goal.MGAIN },
                    ]}
                    isSearchable={false}
                    placeholder={'Select goal'}
                    onChange={(e) => {
                      setGoal(e ? e.value : null);
                    }}
                    styles={customSelectStyles}
                  />
                </div>
              </div>
            </div>

            <button
              type="button"
              id="calculate-button"
              className={
                'w-full rounded-lg bg-black p-3 font-medium text-white transition-all hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-50'
              }
              onClick={calculateCalories}
              disabled={
                !age ||
                !gender ||
                !weight ||
                _.isNull(activityLevel) ||
                _.isNull(goal)
              }
            >
              Calculate
            </button>
          </form>

          <div
            id="result"
            className="mt-8 rounded-lg border border-gray-200 bg-gray-50 p-6 text-center text-lg font-semibold"
          >
            Daily Calories:{' '}
            <span className="text-black">{targetCalories || '--'} Cals</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalorieCalculator;
